<template>
  <div class="v-email-double-validation">
    <v-input
      ref="emailInput"
      :value="email"
      :placeholder="emailPlaceholder"
      :name="input1Name"
      :tooltip="input1Tooltip"
      :required="true"
      :error="emailError"
      :invalid="isEmailInvalid"
      @input="onEmailInput"
      @blur="validateEmail"
      type="email"
    ></v-input>
    <v-input
      ref="confirmEmailInput"
      :value="confirmEmail"
      :placeholder="confirmEmailPlaceholder"
      :name="input2Name"
      :tooltip="input2Tooltip"
      :required="true"
      :error="confirmEmailError"
      :invalid="isConfirmEmailInvalid"
      @input="onConfirmEmailInput"
      @blur="validateConfirmEmail"
      type="email"
    ></v-input>
  </div>
</template>

<script>
export default {
  name: "v-email-double-validation",
  props: {
    value: {
      type: String,
      default: ""
    },
    // Input names props
    input1Name: {
      type: String,
      default: "Email"
    },
    input2Name: {
      type: String,
      default: "Email-repeated"
    },
    // Tooltip props
    input1Tooltip: {
      type: String,
      default: ""
    },
    input2Tooltip: {
      type: String,
      default: ""
    },
    // Initial email value
    currentEmail: {
      type: String,
      default: ""
    },
    // Placeholder text props with defaults
    emailPlaceholder: {
      type: String,
      default: "Email"
    },
    confirmEmailPlaceholder: {
      type: String,
      default: "Repeat email"
    },
    // Error message props with defaults
    missingEmailError: {
      type: String,
      default: "Your email is required"
    },
    invalidEmailError: {
      type: String,
      default: "Please enter a valid email address"
    },
    missingConfirmEmailError: {
      type: String,
      default: "Please repeat your email to prevent typos"
    },
    emailMismatchError: {
      type: String,
      default: "Email addresses do not match"
    }
  },
  data() {
    return {
      email: this.currentEmail || "",
      confirmEmail: this.currentEmail || "",
      isEmailInvalid: false,
      isConfirmEmailInvalid: false,
      emailError: "",
      confirmEmailError: ""
    };
  },
  watch: {
    currentEmail(newValue) {
      // Update both fields if the currentEmail prop changes
      this.email = newValue || "";
      this.confirmEmail = newValue || "";
    }
  },
  mounted() {
    // Initialize error messages from props
    this.emailError = this.missingEmailError;
    this.confirmEmailError = this.missingConfirmEmailError;

    // Find the underlying form elements
    this.emailElement = this.$refs.emailInput.$refs.formElement;
    this.confirmEmailElement = this.$refs.confirmEmailInput.$refs.formElement;

    // Add form validation
    this.emailElement.addEventListener("invalid", this.preventInvalidSubmit);
    this.confirmEmailElement.addEventListener(
      "invalid",
      this.preventInvalidSubmit
    );

    // Add extra validation before form submission
    const form = this.emailElement.closest("form");
    if (form) {
      form.addEventListener("submit", this.validateBeforeSubmit);
    }

    // Emit the initial value if currentEmail is provided
    if (this.currentEmail) {
      this.$emit("input", this.currentEmail);
    }
  },
  beforeDestroy() {
    // Clean up event listeners
    if (this.emailElement) {
      this.emailElement.removeEventListener(
        "invalid",
        this.preventInvalidSubmit
      );
    }
    if (this.confirmEmailElement) {
      this.confirmEmailElement.removeEventListener(
        "invalid",
        this.preventInvalidSubmit
      );
    }

    const form = this.emailElement && this.emailElement.closest("form");
    if (form) {
      form.removeEventListener("submit", this.validateBeforeSubmit);
    }
  },
  methods: {
    onEmailInput(event) {
      // Extract the value from the event
      this.email = event.target.value;

      // Emit standard v-model input event with the value
      this.$emit("update", {
        target: {
          value: this.email
        }
      });

      // Forward the original DOM event for direct hooks
      this.$emit("update", event);

      // If user changes first email, we should revalidate the confirmation email
      if (this.confirmEmail) {
        this.validateConfirmEmail();
      }
    },
    onConfirmEmailInput(event) {
      // Extract the value from the event
      this.confirmEmail = event.target.value;
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.emailError = this.missingEmailError;
        this.isEmailInvalid = true;
        this.emailElement.setCustomValidity(this.missingEmailError);
      } else if (!emailRegex.test(this.email)) {
        this.emailError = this.invalidEmailError;
        this.isEmailInvalid = true;
        this.emailElement.setCustomValidity(this.invalidEmailError);
      } else {
        this.isEmailInvalid = false;
        this.emailElement.setCustomValidity("");
      }

      // If confirmation email is already filled, validate it again
      if (this.confirmEmail) {
        this.validateConfirmEmail();
      }
    },
    validateConfirmEmail() {
      if (!this.confirmEmail) {
        this.confirmEmailError = this.missingConfirmEmailError;
        this.isConfirmEmailInvalid = true;
        this.confirmEmailElement.setCustomValidity(
          this.missingConfirmEmailError
        );
      } else if (this.confirmEmail !== this.email) {
        this.confirmEmailError = this.emailMismatchError;
        this.isConfirmEmailInvalid = true;
        this.confirmEmailElement.setCustomValidity(this.emailMismatchError);
      } else {
        this.isConfirmEmailInvalid = false;
        this.confirmEmailElement.setCustomValidity("");
      }
    },
    preventInvalidSubmit(event) {
      event.preventDefault();
    },
    validateBeforeSubmit(event) {
      // Revalidate both fields before form submission
      this.validateEmail();
      this.validateConfirmEmail();

      // If either field is invalid, prevent submission
      if (this.isEmailInvalid || this.isConfirmEmailInvalid) {
        event.preventDefault();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-email-double-validation {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  & > .v-input {
    flex-direction: column;
  }
}
</style>
