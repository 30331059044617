<template>
  <div class="v-byline">
    <div v-if="authorName" :class="[date && 'v-byline__dot']">
      {{ authorName }}
    </div>
    <div v-if="date">
      <v-date :date="date" calendar></v-date>
    </div>
    <div v-if="speakText" class="v-byline__speak-text">
      <v-speak-text show-label />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    authorName: {
      type: String,
      default: null
    },
    date: {
      type: [Number, String],
      default: null
    },
    speakText: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-byline {
  font-size: 0.9rem;
  text-transform: none;
  display: flex;

  &__dot:after {
    content: "·";
    margin: 0 4px;
  }

  &__speak-text {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    @media print {
      display: none;
    }
  }
}
</style>
