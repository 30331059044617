<template>
  <header
    :class="['v-hero-split', wide ? 'is-wide' : '']"
    :data-content-position-mobile="mobileContentBottom ? 'bottom' : 'top'"
    :data-content-position-desktop="desktopContentRight ? 'right' : 'left'"
    :style="computedStyles"
  >
    <div :class="['media']" v-theme="themeVariant ? {} : null">
      <template v-if="!video && (resolveUrl(imgSrc) || resolveUrl(imgMdSrc))">
        <picture>
          <source
            :srcset="resolveUrl(imgSrc) || resolveUrl(imgMdSrc)"
            media="(max-width: 767px)"
          />
          <source
            :srcset="resolveUrl(imgMdSrc) || resolveUrl(imgSrc)"
            media="(min-width: 768px)"
          />
          <img
            :src="resolveUrl(imgSrc) || resolveUrl(imgMdSrc)"
            :alt="imgAltText"
          />
        </picture>
      </template>

      <template v-if="video">
        <div class="video">
          <div
            v-if="viewport.isClient && viewport.isDesktop"
            class="desktop embed"
            key="desktop"
          >
            <slot name="video-desktop"></slot>
          </div>
          <div
            v-else-if="viewport.isClient && viewport.isMobile"
            class="mobile embed"
            key="mobile"
          >
            <slot name="video-mobile"></slot>
          </div>
          <div v-else class="placeholder"></div>
        </div>
      </template>
    </div>

    <article :class="['article', alignmentClass]" v-theme="themeOptions">
      <div
        class="title-container"
        v-if="
          (viewport.isMobile && titleTextMobile) ||
            (!viewport.isMobile && titleTextDesktop)
        "
      >
        <template v-if="viewport.isMobile && titleTextMobile">
          <v-text tag="h1" :font-size="titleFontSizeMobile" bold>
            {{ titleTextMobile }}
          </v-text>
        </template>
        <template v-else-if="!viewport.isMobile && titleTextDesktop">
          <v-text tag="h1" :font-md-size="titleFontSizeDesktop" bold>
            {{ titleTextDesktop }}
          </v-text>
        </template>
      </div>

      <div
        class="text-container"
        v-if="
          (viewport.isMobile && contentTextMobile) ||
            (!viewport.isMobile && contentTextDesktop)
        "
      >
        <template v-if="viewport.isMobile && contentTextMobile">
          <v-text :font-size="textFontSizeMobile">
            <div class="inner-text" v-html="contentTextMobile"></div>
          </v-text>
        </template>
        <template v-else-if="!viewport.isMobile && contentTextDesktop">
          <v-text :font-md-size="textFontSizeDesktop">
            <div class="inner-text" v-html="contentTextDesktop"></div>
          </v-text>
        </template>
      </div>

      <div class="button-container">
        <slot name="button"></slot>
      </div>
    </article>
  </header>
</template>

<script>
import viewportMixin from "../../../mixin.viewport";
import urlUtils from "../../../mixin.url.utils";

export default {
  name: "VHeroSplit",
  mixins: [viewportMixin, urlUtils],
  props: {
    video: {
      type: Boolean,
      default: false
    },

    themeVariant: {
      type: String,
      default: ""
    },
    transparentButtons: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    desktopContentRight: {
      type: Boolean,
      default: false
    },
    mobileContentBottom: {
      type: Boolean,
      default: false
    },
    alignMobile: {
      type: String,
      default: "left"
    },
    minHeightDesktop: {
      type: String
    },
    imgSrc: {
      type: String,
      default: null
    },
    imgMdSrc: {
      type: String,
      default: null
    },
    imgAltText: {
      type: String,
      default: ""
    },
    titleTextMobile: {
      type: String,
      default: ""
    },
    titleTextDesktop: {
      type: String,
      default: ""
    },
    contentTextMobile: {
      type: String,
      default: ""
    },
    contentTextDesktop: {
      type: String,
      default: ""
    },
    button1Text: {
      type: String,
      default: ""
    },
    button2Text: {
      type: String,
      default: ""
    },
    titleFontSizeMobile: {
      type: String,
      default: "8"
    },
    titleFontSizeDesktop: {
      type: String,
      default: "9"
    },
    textFontSizeMobile: {
      type: String,
      default: "4"
    },
    textFontSizeDesktop: {
      type: String,
      default: "5"
    },
    buttonSize: {
      type: String,
      default: "medium"
    },
    customVideoMobile: {
      type: String,
      default: ""
    },
    customVideoDesktop: {
      type: String,
      default: ""
    }
  },
  computed: {
    themeOptions() {
      // If themeVariant is empty, return null to disable theme
      if (!this.themeVariant) {
        return null;
      }
      return {
        variant: this.themeVariant,
        buttons: this.transparentButtons
      };
    },
    computedStyles() {
      return {
        ...(this.minHeightDesktop
          ? { "--height-desktop": this.minHeightDesktop + "px" }
          : {})
      };
    },
    alignmentClass() {
      return `align-mobile--${this.alignMobile}`;
    },
    hasValidImage() {
      return this.resolveUrl(this.imgSrc) || this.resolveUrl(this.imgMdSrc);
    }
  }
};
// checkout https://www.npmjs.com/package/stream-vue
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";
@import "./v-hero-split-grid.scss";
@import "./v-hero-split.scss";
@import "../shared/_shared-hero-button-styles.scss";
</style>
