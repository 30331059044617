<template>
  <div class="v-icon" :class="[!width && `v-icon--${size}`]">
    <div
      class="v-icon__scaler"
      :class="[!width && `v-icon--${size}`, color && `v-icon--color-${color}`]"
      :style="{
        width: !!width && `${width}px`,
        height: !!height && `${height}px`
      }"
    >
      <img v-if="src" :src="src" :alt="alt" />
      <Sprite v-if="name" :name="name" />
    </div>
    <div v-if="!!this.$slots.default" class="v-icon__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// Built-in svg icons
import Sprite from "./sprite";

// Allowed sizes
const sizes = ["xxsmall", "xsmall", "small", "medium", "large", "xlarge"];
const colors = ["light", "dark", "primary"];

// Export component
export default {
  components: {
    Sprite
  },
  props: {
    src: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "small",
      validator(value) {
        return sizes.includes(value);
      }
    },
    name: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null,
      validator(value) {
        return value === null || colors.includes(value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-icon {
  display: inline-flex;
  align-items: center;

  &--color-light {
    color: theme-color("light") !important;
  }
  &--color-dark {
    color: theme-color("dark") !important;
  }
  &--color-primary {
    color: theme-color("primary") !important;
  }

  &__content {
    margin-left: 0.5 * $spacer;
  }

  $this: &;

  &__scaler {
    display: inline-flex;
    align-items: center;

    &#{$this}--xxsmall {
      width: 16px;
    }

    &#{$this}--xsmall {
      width: 24px;
    }

    &#{$this}--small {
      width: 40px;
    }

    &#{$this}--medium {
      width: 60px;
    }

    &#{$this}--large {
      width: 80px;
    }

    &#{$this}--xlarge {
      width: 100px;
    }
  }

  img,
  svg {
    width: 100%;
  }
}
</style>
