var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-chat-bubble row no-gutters"},[_c('div',{class:[
      'd-flex',
      'v-chat-bubble__align',
      _vm.member && 'v-chat-bubble__align--right',
      _vm.fixedWidth && 'col-12 col-sm-11 col-md-8',
      _vm.fixedWidth && _vm.member && 'offset-sm-1 offset-md-4'
    ]},[(_vm.caseworker)?_c('div',{staticClass:"v-chat-bubble__icon"},[_c('v-icon',{attrs:{"name":"logo","size":"xsmall"}})],1):_vm._e(),_c('div',{staticClass:"v-chat-bubble__wrapper"},[_c('div',{class:[
          'v-chat-bubble__content',
          _vm.member && 'v-chat-bubble__content--member',
          _vm.caseworker && 'v-chat-bubble__content--caseworker'
        ]},[_vm._t("default")],2),(_vm.date)?_c('v-text',{class:[
          'v-chat-bubble__meta',
          _vm.member && 'v-chat-bubble__meta--member',
          'd-print-none'
        ],attrs:{"size":"small"}},[_c('v-date',{attrs:{"date":_vm.date,"calendar":""}}),(_vm.caseworkerName)?_c('span',[_vm._v(", "),_c('v-text',{attrs:{"tag":_vm.caseworkerUrl ? 'a' : 'span',"href":_vm.caseworkerUrl}},[_vm._v(_vm._s(_vm.caseworkerName))])],1):_vm._e(),(_vm.caseworkerDepartment)?_c('span',[_vm._v(", "+_vm._s(_vm.caseworkerDepartment))]):_vm._e()],1):_vm._e(),_c('v-text',{staticClass:"d-none d-print-block"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.memberName || _vm.caseworkerName))]),(_vm.caseworkerDepartment)?_c('span',[_vm._v(", "+_vm._s(_vm.caseworkerDepartment))]):_vm._e(),_vm._v(", "),_c('v-date',{attrs:{"date":_vm.date,"format":"L [kl.] HH:MM"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }