import vCard from "./v-card.vue";
import vCardGroup from "./v-card-group.vue";
import vContactCard from "./variants/v-contact-card/v-contact-card.vue";
import vNavigationCards from "./variants/v-navigation-card/v-navigation-cards.vue";
import vNavigationCard from "./variants/v-navigation-card/v-navigation-card.vue";

export default Vue => {
  Vue.component("v-card", vCard);
  Vue.component("v-card-group", vCardGroup);
  Vue.component("v-contact-card", vContactCard);
  Vue.component("v-navigation-cards", vNavigationCards);
  Vue.component("v-navigation-card", vNavigationCard);
};
