// Dependencies: src/sass/_theme.scss
// Usage: <v-component v-theme="{ variant: 3, buttons: true }">...</v-component>
// Usage: <v-component v-theme>...</v-component> <!-- Will use default variant 0 -->

// Maximum theme variant number (update this when adding new variants)
const MAX_VARIANT = 10;

const ThemeDirective = {
  install(Vue) {
    Vue.directive("theme", {
      bind(el, binding) {
        applyThemeClasses(el, binding);
      },

      update(el, binding) {
        // First remove any existing theme classes
        el.classList.remove("has-theme", "has-theme-buttons");
        for (let i = 0; i <= MAX_VARIANT; i++) {
          el.classList.remove(`theme-variant-${i}`);
        }

        // Then reapply based on current binding
        applyThemeClasses(el, binding);
      }
    });
  }
};

function applyThemeClasses(el, binding) {
  const { value, modifiers, arg } = binding;

  // When v-theme is used without a value, binding.value will be undefined
  // We should interpret this as "enable theme with default settings"
  const enableTheme =
    value === true ||
    (typeof value === "object" && value) ||
    value === undefined;

  if (enableTheme) {
    el.classList.add("has-theme");

    // Handle variant as arg (v-theme:2) or as value (v-theme="{ variant: 2 }")
    const variant =
      arg || (typeof value === "object" && value && value.variant) || "0";
    el.classList.add(`theme-variant-${variant}`);

    // Handle buttons either as modifier (v-theme.buttons) or as value (v-theme="{ buttons: true }")
    const hasButtons =
      modifiers.buttons ||
      (typeof value === "object" && value && value.buttons);

    if (hasButtons) {
      el.classList.add("has-theme-buttons");
    }
  }
}

export default ThemeDirective;
