<template>
  <div class="v-chat-bubble row no-gutters">
    <div
      :class="[
        'd-flex',
        'v-chat-bubble__align',
        member && 'v-chat-bubble__align--right',
        fixedWidth && 'col-12 col-sm-11 col-md-8',
        fixedWidth && member && 'offset-sm-1 offset-md-4'
      ]"
    >
      <div v-if="caseworker" class="v-chat-bubble__icon">
        <v-icon name="logo" size="xsmall" />
      </div>
      <div class="v-chat-bubble__wrapper">
        <div
          :class="[
            'v-chat-bubble__content',
            member && 'v-chat-bubble__content--member',
            caseworker && 'v-chat-bubble__content--caseworker'
          ]"
        >
          <slot></slot>
        </div>
        <v-text
          v-if="date"
          size="small"
          :class="[
            'v-chat-bubble__meta',
            member && 'v-chat-bubble__meta--member',
            'd-print-none'
          ]"
        >
          <v-date :date="date" calendar /><span v-if="caseworkerName"
            >,
            <v-text :tag="caseworkerUrl ? 'a' : 'span'" :href="caseworkerUrl">{{
              caseworkerName
            }}</v-text> </span
          ><span v-if="caseworkerDepartment">, {{ caseworkerDepartment }}</span>
        </v-text>
        <v-text class="d-none d-print-block">
          <span class="font-weight-bold">{{
            memberName || caseworkerName
          }}</span
          ><span v-if="caseworkerDepartment">, {{ caseworkerDepartment }}</span
          >,
          <v-date :date="date" format="L [kl.] HH:MM" />
        </v-text>
      </div>
    </div>
  </div>
</template>

<script>
import vIcon from "../../v-icon/v-icon.vue";
export default {
  components: { vIcon },
  props: {
    member: {
      type: Boolean,
      default: false
    },
    memberName: {
      type: String,
      default: null
    },
    caseworker: {
      type: Boolean,
      default: false
    },
    caseworkerName: {
      type: String,
      default: null
    },
    caseworkerUrl: {
      type: String,
      default: null
    },
    caseworkerDepartment: {
      type: String,
      default: null
    },
    fixedWidth: {
      type: Boolean,
      default: true
    },
    date: {
      type: [Number, String],
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";

.v-chat-bubble {
  &__align {
    &--right {
      justify-content: flex-end;

      @media print {
        justify-content: flex-start;
      }
    }
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    align-items: flex-end;
    margin-bottom: 28px;
    z-index: 2;
    color: theme-color("primary");
  }

  &__wrapper {
    display: flex;
    margin-left: $spacer;
    min-width: 0;
    flex-direction: column;
  }

  &__meta {
    color: theme-color("placeholder");

    a {
      color: theme-color("placeholder");
      text-decoration: underline;
    }

    &--member {
      text-align: right;
    }
  }

  &__content {
    position: relative;
    padding: $spacer;
    margin-bottom: 7px;
    border-radius: 10px;

    &--member {
      color: theme-color("white");
      background: theme-color("focus");
      border-bottom-right-radius: 14px;

      a {
        color: theme-color("white");
      }
    }

    &--caseworker {
      background: theme-color("light");
      border-bottom-left-radius: 14px;

      a {
        color: theme-color("dark");
      }
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      height: 20px;
      width: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 16px;
      height: 20px;
      background: theme-color("white");
    }

    &--caseworker {
      &:before {
        left: -7px;
        border-bottom-right-radius: 15px;
        background: theme-color("light");
      }

      &:after {
        left: -16px;
        border-bottom-right-radius: 10px;
      }
    }

    &--member {
      &:before {
        right: -7px;
        border-bottom-left-radius: 15px;
        background: theme-color("focus");
      }

      &:after {
        right: -16px;
        border-bottom-left-radius: 10px;
      }
    }
  }

  @media print {
    // page-break-inside: avoid;
    margin-top: $spacer !important;

    & + & {
      margin-top: $spacer * 1.5 !important;
    }

    > div {
      margin-left: 0;
    }

    &__icon {
      display: none;
    }

    &__wrapper {
      margin-left: 0;
      flex-direction: column-reverse;

      ::v-deep .v-text {
        font-size: 14px;
      }
    }

    &__content {
      background: none;
      color: theme-color("dark");
      padding: 0;

      &:before,
      &:after {
        content: none;
      }
    }
  }
}
</style>
