<template>
  <div class="v-magazine py-3">
    <a class="v-magazine__image" :href="url">
      <img :src="imgSrc" :alt="imgAlt || title" />
      <v-text
        class="v-magazine__image__label text-decoration-none"
        font-size="1"
      >
        <v-icon
          size="xsmall"
          name="visibility"
          class="text-primary mr-1"
        ></v-icon>
        Læs bladet
      </v-text>
    </a>
    <div class="flex-grow-1">
      <v-stack size="xxsmall">
        <div class="d-flex justify-content-between pt-1 pt-md-3">
          <div class="v-magazine__meta">
            <span><v-date :date="date" format="L"/></span>
            <span v-if="fileSize">{{ fileSize }}</span>
            <span v-if="fileType">{{ fileType }}</span>
          </div>
          <v-magazine-links
            class="d-none d-sm-flex align-items-center"
            :url="url"
            :file-url="fileUrl"
          />
        </div>
        <v-text
          bold
          font-size="4"
          font-md-size="5"
          tag="a"
          :href="url"
          class="v-magazine__title"
          >{{ title }}</v-text
        >
        <v-text class="d-none d-sm-block" v-html="description"></v-text>
        <v-magazine-links
          class="d-flex flex-column d-sm-none"
          :url="url"
          :file-url="fileUrl"
        />
      </v-stack>
    </div>
  </div>
</template>

<script>
import vMagazineLinks from "./v-magazine-links";

export default {
  components: {
    "v-magazine-links": vMagazineLinks
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    },
    imgAlt: {
      type: String,
      default: null
    },
    fileUrl: {
      type: String,
      default: null
    },
    fileSize: {
      type: String,
      default: null
    },
    fileType: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-magazine {
  display: flex;
  $border: 1px solid theme-color("gray");
  border-top: $border;
  border-bottom: $border;

  & + & {
    border-top: none;
    border-bottom: $border;
  }

  &__image {
    width: 130px;
    position: relative;
    margin-right: $spacer * 1.5;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
    line-height: 0;

    img {
      width: 100%;
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 32px;
      background-color: rgba(255, 255, 255, 0.9);
      opacity: 0;
      transition: opacity 100ms ease-out;
    }

    &:hover &__label {
      opacity: 1;
    }
  }

  &__meta {
    color: theme-color("gray-dark");
    font-size: 0.75rem;
    text-transform: uppercase;

    span + span:before {
      content: "·";
      margin: 0 4px;
    }
  }

  &__title {
    display: block;
    color: theme-color("dark");

    &:hover {
      color: theme-color("primary");
    }
  }
}
</style>
