export default {
  methods: {
    /**
     * Resolves a relative URL to an absolute URL
     * @param {string} url - The URL to resolve
     * @returns {string|null} - The resolved URL or null if input is falsy
     */
    resolveUrl(url) {
      if (!url) {
        return null;
      }

      if (/^(?!https?|\:\/\/)/.test(url) && typeof document !== "undefined") {
        const link = document.createElement("a");
        link.href = url;
        return link.href;
      }

      return url;
    }
  }
};
