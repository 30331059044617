<template>
  <div class="v-speak-text" @click="toggleSpeakText">
    <v-icon v-if="paused || !speaking" name="sound-on" size="xsmall"></v-icon>
    <v-icon v-else name="sound-off" size="xsmall"></v-icon>
    <div v-if="showLabel" class="v-speak-text__label">
      {{ $t("Read out", "components.speak-text.readout") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: false
    },
    elementSelector: {
      type: String,
      default: ".speak-text"
    }
  },
  created() {
    if (!this.voiceLoaded() && this.shouldLoadVoice()) {
      const script = document.createElement("script");
      script.src =
        "https://code.responsivevoice.org/responsivevoice.js?key=ugPtnKp8";
      document.body.appendChild(script);
      window.responsiveVoiceLoaded = true;
    }
  },
  methods: {
    toggleSpeakText() {
      const voice = this.getVoice();
      if (!voice) return;

      if (!this.speaking) {
        this.startSpeaking(voice);
        this.speaking = true;
      } else {
        this.paused ? voice.resume() : voice.pause();
        this.paused = !this.paused;
      }
    },
    startSpeaking(voice) {
      voice.speak(this.getText(), "Danish Female", {
        onend: () => {
          this.speaking = false;
        }
      });
    },
    getVoice() {
      return (typeof window !== "undefined" && window.responsiveVoice) || false;
    },
    voiceLoaded() {
      return (
        (typeof window !== "undefined" && window.responsiveVoiceLoaded) || false
      );
    },
    shouldLoadVoice() {
      return (
        window.location.href.includes("localhost:3000") ||
        window.location.href.includes("3f-design.dk")
      );
    },
    getText() {
      const textElements = document.querySelectorAll(this.elementSelector);

      let text = "";
      for (var i = 0; i < textElements.length; i++) {
        let elementText = textElements[i].textContent.trim();
        text = this.fixText(text, elementText);
      }

      return text;
    },
    fixText(text, elementText) {
      // Fix 3f pronounciation
      elementText = elementText.replace(/3F/gi, '"3,F"');

      // Replace newline and then replace several whitespaces with one, and finally trim.
      text += elementText
        .replace(/\n|\r/g, "")
        .replace(/\s+/g, " ")
        .trim();

      // Add punctuation at end of line if none exists to get a pause in the reading.
      if (
        elementText.length > 1 &&
        text.length > 1 &&
        text.substr(text.length - 1) != "."
      ) {
        text += ".";
      }

      // Add ending spacing for pause
      if (elementText.length > 1) {
        text += " ";
      }

      return text;
    }
  },
  data() {
    return {
      speaking: false,
      paused: false
    };
  }
};
</script>

<style scoped lang="scss">
.v-speak-text {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  :deep svg {
    margin-inline-end: 2px;
    margin-block-end: 1px;
  }

  &__label {
    font-size: 0.9rem;
    text-transform: none;
  }
}
</style>
