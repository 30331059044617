<template>
  <component
    :is="tagName"
    class="v-navigation-card swiper-slide"
    :class="themeClass"
    :href="href"
  >
    <article class="content" v-if="icon.name || title || text">
      <v-icon
        v-if="icon.name"
        :name="icon.name"
        :size="icon.size"
        class="card-icon"
      />
      <h2 class="title" v-if="title">{{ title }}</h2>
      <p class="text" v-if="text">{{ text }}</p>
    </article>
    <footer class="footer" v-if="arrow || buttonText">
      <v-icon
        v-if="arrow && !buttonText"
        name="navigation-link"
        size="small"
        class="nav-icon"
      />
      <v-button v-if="buttonText" small tag="a" :href="href">{{
        buttonText
      }}</v-button>
    </footer>
  </component>
</template>

<script>
export default {
  name: "v-navigation-card",
  props: {
    href: {
      type: String,
      default: "#"
    },
    icon: {
      type: Object,
      default: () => ({}),
      properties: {
        name: {
          type: String,
          default: ""
        },
        size: {
          type: String,
          default: "medium"
        }
      }
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    arrow: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: null
    },
    theme: {
      type: [String, Number],
      default: "0"
    }
  },
  computed: {
    tagName() {
      if (this.tag) return this.tag;
      if (this.buttonText) return "div";
      return "a";
    },
    themeClass() {
      // Convert any value to string and ensure it's valid
      const themeValue = String(this.theme);
      return `theme-variant-${themeValue}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/component.scss";
@import "./v-navigation-card.scss";
</style>
