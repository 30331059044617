<template>
  <portal :to="globalTarget">
    <div v-if="show" :class="['v-alert-global', themeClass]">
      <div class="v-alert-global__container container-fluid pr-2">
        <div
          :class="[
            'v-alert-global__content',
            themeClass,
            { 'has-icon': dismissible }
          ]"
        >
          <slot></slot>
        </div>
        <div
          v-if="dismissible"
          :class="['v-alert-global__icon', themeClass]"
          @click="dismiss()"
        >
          <v-icon name="close" size="xxsmall" />
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

export default {
  props: {
    expanded: {
      type: Boolean,
      default: true
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    dismissibleId: {
      type: String,
      default: null
    },
    globalTarget: {
      type: String,
      default: "globalAlerts"
    },
    theme: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      show: true,
      globalAlertKey: "dismissedGlobalAlerts"
    };
  },
  computed: {
    themeClass() {
      if (this.theme === "warning") {
        return "theme-warning";
      }
      return "";
    }
  },
  mounted() {
    this.checkDismissedStatus();
  },
  methods: {
    checkDismissedStatus() {
      const globalDismissedAlerts = this.getGlobalDismissedAlerts();
      if (globalDismissedAlerts.includes(this.dismissibleId)) {
        this.show = false;
      }
    },
    getGlobalDismissedAlerts() {
      const alertsString = localStorage.getItem(this.globalAlertKey);
      if (!alertsString) return [];

      try {
        const alerts = JSON.parse(alertsString);
        return Array.isArray(alerts) ? alerts : [alerts];
      } catch (error) {
        console.error("Error parsing dismissed global alerts:", error);
        localStorage.removeItem(this.globalAlertKey);
        return [];
      }
    },
    setGlobalDismissedAlerts(alerts) {
      localStorage.setItem(this.globalAlertKey, JSON.stringify(alerts));
    },
    dismiss() {
      this.show = false;
      if (!this.dismissibleId) return;

      const globalDismissedAlerts = this.getGlobalDismissedAlerts();
      if (!globalDismissedAlerts.includes(this.dismissibleId)) {
        globalDismissedAlerts.push(this.dismissibleId);
        this.setGlobalDismissedAlerts(globalDismissedAlerts);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";
@import "./v-alert-global.scss";
</style>
