<template>
  <portal to="notifications">
    <transition name="v-toast" appear>
      <div
        v-if="!hide"
        @click="hideToast()"
        :class="[
          'v-toast',
          success && 'v-toast--success',
          info && 'v-toast--info',
          error && 'v-toast--error'
        ]"
      >
        <div class="v-toast__icon">
          <v-icon name="success" v-if="success" />
          <v-icon name="error" v-else-if="error" />
          <v-icon name="info" v-else />
        </div>
        <v-text font-size="2" class="v-toast__content">
          <slot></slot>
        </v-text>
        <v-icon
          class="v-toast__close"
          name="close"
          size="xxsmall"
          v-if="dismissible"
        />
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  data() {
    return {
      hide: true
    };
  },
  methods: {
    hideToast() {
      // Skip if dismissibility is disabled
      if (!this.dismissible) {
        return;
      }

      // Hide tpast
      this.hide = true;

      // Emit "close" event after animation finishes
      setTimeout(() => {
        this.$emit("close");
      }, 250);

      // Clear timeout
      if (this._timeout) {
        clearTimeout(this._timeout);
      }
    }
  },
  mounted() {
    // SHow toast when mounted
    this.hide = false;
  },
  watch: {
    hide(hide) {
      // If state changes to 'show'
      if (!hide && !!this.dismissible) {
        // Hide toast after 10 seconds
        this._timeout = setTimeout(() => {
          // Hide toast
          this.hide = true;

          // Emit "close" event after animation finishes
          setTimeout(() => {
            this.$emit("close");
          }, 250);
        }, 10000);
      }
    }
  },
  props: {
    dismissible: {
      type: Boolean,
      default: true
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default() {
        return this.$props.success === false && this.$props.error === false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-toast {
  cursor: pointer;
  margin-bottom: $spacer;
  max-width: 400px;
  position: relative;
  padding: $spacer;
  border-radius: $border-radius * 0.5;
  transition: all 250ms ease-out;
  box-shadow: 0 18px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  &__content {
    color: theme-color("white");

    ::v-deep a {
      font-weight: inherit;
      color: inherit !important;
      text-decoration: underline;

      &:hover,
      &:visited {
        color: inherit !important;
      }
    }
  }

  &__close {
    position: absolute;
    top: 6px;
    right: 6px;
    color: theme-color("white");
  }

  &__icon {
    position: relative;
    color: theme-color("white");
    top: 3px;
    margin-right: $spacer * 0.5;
    .v-icon {
      width: 28px;
      aspect-ratio: 1;
    }
  }

  &-enter-active {
    transition-delay: 500ms;
  }

  &-enter,
  &-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }

  &--info {
    background-color: theme-color("secondary");
  }

  &--error {
    background-color: theme-color("danger");
  }

  &--success {
    background-color: theme-color("success");
  }
}
</style>
