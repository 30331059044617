/**
 * Viewport Mixin
 *
 * Provides responsive viewport information to Vue components.
 * Handles window resize events with debounced updates to prevent performance issues
 * when multiple components use this mixin simultaneously.
 */

// Dependencies
import debounce from "lodash.debounce";

export default {
  data() {
    return {
      viewport: {
        width: typeof window !== "undefined" ? window.innerWidth : 1024, // Default to desktop
        height: typeof window !== "undefined" ? window.innerHeight : 768,
        isMobile: false, // Default to desktop view
        isDesktop: true, // Default to desktop view
        isClient: false
      }
    };
  },

  beforeMount() {
    this.viewport.isClient = typeof window !== "undefined";
  },

  mounted() {
    if (this.viewport.isClient) {
      // Force immediate viewport update on mount
      this.updateViewport();
      // Then start listening for resize events
      window.addEventListener("resize", this.debouncedUpdateViewport);
    }
  },

  beforeDestroy() {
    if (this.viewport.isClient) {
      window.removeEventListener("resize", this.debouncedUpdateViewport);
    }
  },

  methods: {
    updateViewport() {
      if (!this.viewport.isClient) return;

      const width = window.innerWidth;
      const height = window.innerHeight;

      this.viewport = {
        width,
        height,
        isMobile: width < 768,
        isDesktop: width >= 768,
        isClient: true
      };
    },

    debouncedUpdateViewport: debounce(function() {
      this.updateViewport();
    }, 100)
  }
};
