<template>
  <v-icon
    v-if="large"
    class="v-accordion__caret v-accordion__caret--large"
    name="arrow-key-down"
    size="small"
  />
  <v-icon
    v-else
    class="v-accordion__caret v-accordion__caret--small"
    name="arrow-key-down"
    size="xsmall"
  />
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-accordion__caret {
  color: theme-color("dark");
  transition: transform 0.1s;

  &--small {
    padding: 2px 0;
    transform: rotate(180deg);

    .v-accordion--collapsed & {
      transform: rotate(0);
    }
  }

  &--large {
    --large-scale-mobile: 0.7;
    transform: rotate(-180deg);

    @include media-breakpoint-down(xs) {
      transform: rotate(-180deg) scale(var(--large-scale-mobile));
    }

    color: theme-color("primary");

    .v-accordion--collapsed & {
      transform: rotate(0deg);
      color: #8c8c8d;
      @include media-breakpoint-down(xs) {
        transform: rotate(0deg) scale(var(--large-scale-mobile));
      }
    }
  }
}
</style>
