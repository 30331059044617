var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:['v-hero-split', _vm.wide ? 'is-wide' : ''],style:(_vm.computedStyles),attrs:{"data-content-position-mobile":_vm.mobileContentBottom ? 'bottom' : 'top',"data-content-position-desktop":_vm.desktopContentRight ? 'right' : 'left'}},[_c('div',{directives:[{name:"theme",rawName:"v-theme",value:(_vm.themeVariant ? {} : null),expression:"themeVariant ? {} : null"}],class:['media']},[(!_vm.video && (_vm.resolveUrl(_vm.imgSrc) || _vm.resolveUrl(_vm.imgMdSrc)))?[_c('picture',[_c('source',{attrs:{"srcset":_vm.resolveUrl(_vm.imgSrc) || _vm.resolveUrl(_vm.imgMdSrc),"media":"(max-width: 767px)"}}),_c('source',{attrs:{"srcset":_vm.resolveUrl(_vm.imgMdSrc) || _vm.resolveUrl(_vm.imgSrc),"media":"(min-width: 768px)"}}),_c('img',{attrs:{"src":_vm.resolveUrl(_vm.imgSrc) || _vm.resolveUrl(_vm.imgMdSrc),"alt":_vm.imgAltText}})])]:_vm._e(),(_vm.video)?[_c('div',{staticClass:"video"},[(_vm.viewport.isClient && _vm.viewport.isDesktop)?_c('div',{key:"desktop",staticClass:"desktop embed"},[_vm._t("video-desktop")],2):(_vm.viewport.isClient && _vm.viewport.isMobile)?_c('div',{key:"mobile",staticClass:"mobile embed"},[_vm._t("video-mobile")],2):_c('div',{staticClass:"placeholder"})])]:_vm._e()],2),_c('article',{directives:[{name:"theme",rawName:"v-theme",value:(_vm.themeOptions),expression:"themeOptions"}],class:['article', _vm.alignmentClass]},[(
        (_vm.viewport.isMobile && _vm.titleTextMobile) ||
          (!_vm.viewport.isMobile && _vm.titleTextDesktop)
      )?_c('div',{staticClass:"title-container"},[(_vm.viewport.isMobile && _vm.titleTextMobile)?[_c('v-text',{attrs:{"tag":"h1","font-size":_vm.titleFontSizeMobile,"bold":""}},[_vm._v(" "+_vm._s(_vm.titleTextMobile)+" ")])]:(!_vm.viewport.isMobile && _vm.titleTextDesktop)?[_c('v-text',{attrs:{"tag":"h1","font-md-size":_vm.titleFontSizeDesktop,"bold":""}},[_vm._v(" "+_vm._s(_vm.titleTextDesktop)+" ")])]:_vm._e()],2):_vm._e(),(
        (_vm.viewport.isMobile && _vm.contentTextMobile) ||
          (!_vm.viewport.isMobile && _vm.contentTextDesktop)
      )?_c('div',{staticClass:"text-container"},[(_vm.viewport.isMobile && _vm.contentTextMobile)?[_c('v-text',{attrs:{"font-size":_vm.textFontSizeMobile}},[_c('div',{staticClass:"inner-text",domProps:{"innerHTML":_vm._s(_vm.contentTextMobile)}})])]:(!_vm.viewport.isMobile && _vm.contentTextDesktop)?[_c('v-text',{attrs:{"font-md-size":_vm.textFontSizeDesktop}},[_c('div',{staticClass:"inner-text",domProps:{"innerHTML":_vm._s(_vm.contentTextDesktop)}})])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"button-container"},[_vm._t("button")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }