<template>
  <div
    v-if="carrousel"
    class="v-navigation-cards v-navigation-cards__carrousel"
  >
    <div class="swiper">
      <div class="swiper-wrapper">
        <slot></slot>
      </div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-scrollbar"></div>
    </div>
  </div>

  <div v-else class="v-navigation-cards v-navigation-cards__grid">
    <slot></slot>
  </div>
</template>

<script>
// Dependency: Swiper Core version 10.3.1
// Import core Swiper and modules
import Swiper from "swiper/swiper.mjs";
import Navigation from "swiper/modules/navigation.mjs";
import Scrollbar from "swiper/modules/scrollbar.mjs";
import Keyboard from "swiper/modules/keyboard.mjs";
import Mousewheel from "swiper/modules/mousewheel.mjs";
import A11y from "swiper/modules/a11y.mjs";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/modules/navigation.scss";
import "swiper/modules/scrollbar.scss";
import "swiper/modules/keyboard.scss";
import "swiper/modules/mousewheel.scss";
import "swiper/modules/a11y.scss";

export default {
  name: "v-navigation-cards",
  props: {
    carrousel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      swiper: null
    };
  },
  mounted() {
    // Only initialize Swiper if carrousel is true
    if (this.carrousel) {
      this.$nextTick(() => {
        this.initSwiper();
      });
    }
  },
  methods: {
    initSwiper() {
      // Find the swiper container in this component
      const swiperContainer = this.$el.querySelector(".swiper");

      if (!swiperContainer) return;

      this.swiper = new Swiper(swiperContainer, {
        modules: [Navigation, Scrollbar, Keyboard, Mousewheel, A11y],
        enabled: true,
        slidesPerView: 1.4,
        updateOnWindowResize: true,
        resizeObserver: true,
        observer: true,
        observeParents: true,
        breakpointsBase: "container",
        spaceBetween: 32,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
          pageUpDown: true
        },
        mousewheel: {
          enabled: true,
          releaseOnEdges: true
        },
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
          draggable: true
        },
        a11y: {
          enabled: true,
          scrollOnFocus: true
        },
        grabCursor: false,
        breakpoints: {
          640: {
            slidesPerView: 2.2
          },
          768: {
            slidesPerView: 3.2
          },
          1024: {
            slidesPerView: 4.2
          }
        },
        slideFullyVisibleClass: "swiper-slide-visible"
      });
    }
  },
  beforeDestroy() {
    // Safely destroy Swiper if it exists and has a destroy method
    if (this.swiper && typeof this.swiper.destroy === "function") {
      try {
        this.swiper.destroy(true, true); // true, true for complete cleanup
      } catch (error) {
        console.warn("Error destroying Swiper instance:", error);
      }
      this.swiper = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/component.scss";
@import "./v-navigation-cards-carrousel.scss";
@import "./v-navigation-cards-grid.scss";
</style>
