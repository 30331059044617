<template>
  <!-- UI icons below: -->
  <svg
    v-if="name == 'menu'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M161-251.23q-13.5 0-23.25-9.76T128-284.26q0-13.51 9.75-23.24 9.75-9.73 23.25-9.73h638q13.5 0 23.25 9.76 9.75 9.75 9.75 23.27 0 13.51-9.75 23.24-9.75 9.73-23.25 9.73H161ZM161-448q-13.5 0-23.25-9.76T128-481.03q0-13.51 9.75-23.24Q147.5-514 161-514h638q13.5 0 23.25 9.76t9.75 23.27q0 13.51-9.75 23.24Q812.5-448 799-448H161Zm0-196.77q-13.5 0-23.25-9.76-9.75-9.75-9.75-23.27 0-13.51 9.75-23.24 9.75-9.73 23.25-9.73h638q13.5 0 23.25 9.76t9.75 23.27q0 13.51-9.75 23.24-9.75 9.73-23.25 9.73H161Z"
    />
  </svg>
  <svg
    v-else-if="name == 'close'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M481-433.69 276.15-227.85q-10.61 10.62-23.77 10.5-13.15-.11-24.53-11.5-10.39-11.38-9.89-23.15.5-11.77 10.89-23.15L432.69-480 227.85-686.85q-9.62-10.61-10-22.77-.39-12.15 10-23.53 11.38-11.39 24.15-11.89 12.77-.5 24.15 10.89L481-528.31l202.85-205.84q10.61-10.62 23.77-10.5 13.15.11 24.53 11.5 10.39 11.38 9.89 23.15-.5 11.77-10.89 23.15L527.31-480l203.84 204.85q9.62 9.61 10.5 22.27.89 12.65-9.5 24.03-11.38 11.39-24.15 11.89-12.77.5-24.15-10.89L481-433.69Z"
    />
  </svg>
  <svg
    v-else-if="name == 'attachment'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M330.46-246.08q-101.88 0-172.17-71.11Q88-388.31 88-490.01q0-101.7 70.29-172.54 70.29-70.83 172.17-70.83h369.23q72.46 0 122.39 50.24Q872-632.9 872-560.03q0 72.88-49.92 123.03-49.93 50.15-122.39 50.15H345.92q-41.92 0-72.03-30.08-30.12-30.08-30.12-72.5t29.72-72.8q29.71-30.39 72.43-30.39h303.31q13.6 0 22.8 9.19 9.2 9.19 9.2 22.77t-9.2 23.31q-9.2 9.73-22.8 9.73H345.92q-15.25 0-26.2 11.12t-10.95 26.27q0 15.15 10.95 26.77 10.95 11.61 26.2 11.61h354.54q44.62.77 75.58-31.05T807-559.62q0-44.52-31.4-76.64-31.39-32.12-75.91-32.12H330.46q-74.08.76-125.77 52.72Q153-563.71 153-489.15q0 73.51 51.69 126.06t125.77 51.01h349.77q13.5 0 23.25 9.76t9.75 23.27q0 13.51-9.75 23.24-9.75 9.73-23.25 9.73H330.46Z"
    />
  </svg>
  <svg
    v-else-if="name == 'check-circle'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="m422.46-396.31-92.84-92.84q-9.62-9.62-22.77-10-13.16-.39-23.54 10-10.39 10.38-10.39 23.15 0 12.77 10.39 23.15l107.54 107.54q13.51 13.69 31.52 13.69 18.01 0 31.71-13.69l223.07-223.07q9.62-9.62 10-22.77.39-13.16-10-23.54-10.38-10.39-23.15-10.39-12.77 0-23.15 10.39L422.46-396.31ZM480.13-88q-81.31 0-152.89-30.86-71.57-30.86-124.52-83.76-52.95-52.9-83.83-124.42Q88-398.55 88-479.87q0-81.56 30.92-153.37 30.92-71.8 83.92-124.91 53-53.12 124.42-83.48Q398.67-872 479.87-872q81.55 0 153.35 30.34 71.79 30.34 124.92 83.42 53.13 53.08 83.49 124.84Q872-561.64 872-480.05q0 81.59-30.34 152.83-30.34 71.23-83.41 124.28-53.07 53.05-124.81 84Q561.7-88 480.13-88Zm-.13-66q136.51 0 231.26-94.74Q806-343.49 806-480t-94.74-231.26Q616.51-806 480-806t-231.26 94.74Q154-616.51 154-480t94.74 231.26Q343.49-154 480-154Zm0-326Z"
    />
  </svg>
  <svg
    v-else-if="name == 'search'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M378.54-328.77q-105.92 0-179.58-73.65-73.65-73.66-73.65-179.58 0-105.92 73.65-179.58 73.66-73.65 179.58-73.65 105.92 0 179.58 73.65 73.65 73.66 73.65 179.58 0 42.69-13.27 83.69t-37.27 70.69l230.31 230.31q9.61 9.62 10 22.77.38 13.16-10 23.54-10.39 10.38-23.16 10.38-12.76 0-23.15-10.38L535.92-380.31q-30 24.54-73.5 38.04t-83.88 13.5Zm0-66q79.61 0 133.42-53.81 53.81-53.8 53.81-133.42 0-79.62-53.81-133.42-53.81-53.81-133.42-53.81-79.62 0-133.42 53.81-53.81 53.8-53.81 133.42 0 79.62 53.81 133.42 53.8 53.81 133.42 53.81Z"
    />
  </svg>
  <svg
    v-else-if="name == 'lock'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M260.62-76q-38.85 0-64.74-25.88Q170-127.77 170-166.62v-358.76q0-38.85 25.88-64.74Q221.77-616 260.62-616H292v-80q0-79.85 54.77-134.92Q401.53-886 480-886t133.23 55.08Q668-775.85 668-696v80h31.38q38.85 0 64.74 25.88Q790-564.23 790-525.38v358.76q0 38.85-25.88 64.74Q738.23-76 699.38-76H260.62Zm0-66h438.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-358.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H260.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v358.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92Zm219.54-128q31.15 0 52.49-21.51Q554-313.01 554-344.16t-21.51-52.49Q510.99-418 479.84-418t-52.49 21.51Q406-374.99 406-343.84t21.51 52.49Q449.01-270 480.16-270ZM358-616h244v-80q0-51-35.62-87.5t-86.5-36.5q-50.88 0-86.38 36.5Q358-747 358-696v80ZM236-142v-408 408Z"
    />
  </svg>
  <svg
    v-else-if="name == 'pin'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M480-174.92q111.77-92.16 180.81-195.16t69.04-181.07q0-105.77-71.5-181.2-71.5-75.42-178.35-75.42t-178.35 75.42q-71.5 75.43-71.5 181.2 0 78.07 69.04 180.57T480-174.92Zm0 64.15q-13.38 0-26.27-4.08-12.88-4.07-24.81-14-49.69-44.23-97.04-94.88-47.34-50.65-84.65-104.23-37.31-53.58-60.19-110.31-22.89-56.73-22.89-112.88 0-125.93 89.16-223.89Q342.46-873 480-873t226.69 97.96q89.16 97.96 89.16 223.89 0 56.15-22.89 112.5-22.88 56.34-59.81 110.3-36.92 53.97-84.27 104.23-47.34 50.27-97.03 94.5-11.65 9.93-24.88 14.39-13.24 4.46-26.97 4.46Zm0-444.61Zm.14 74.61q30.71 0 52.59-22.02 21.89-22.02 21.89-52.73 0-30.71-22.03-52.6Q510.57-630 479.86-630q-30.71 0-52.59 22.02-21.89 22.02-21.89 52.73 0 30.71 22.03 52.6 22.02 21.88 52.73 21.88Z"
    />
  </svg>
  <svg
    v-else-if="name === 'new'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="m438-438.77-63.85-64.61q-10.61-10.62-23.65-10.5-13.04.11-23.65 10.73-11.39 11.38-11.39 23.65 0 12.27 11.39 23.65l79.53 78.54q13.7 13.69 31.62 13.69t31.62-13.69l165.53-165.54q9.85-9.84 10.62-23.27.77-13.42-10.62-24.03-10.61-10.62-23.27-10.23-12.65.38-23.26 11L438-438.77ZM326.77-105.38l-52.62-90-101.23-22.93q-16.92-4.23-26.5-18.31-9.57-14.07-8.34-31l9.46-104.23-69.31-77.77Q65.77-462.31 65.77-480q0-17.69 12.46-30.38l69.31-77.77-9.46-104.23q-1.23-16.93 8.34-31 9.58-14.08 26.5-18.31l101.23-22.93 52.62-90q8.46-14.69 24.38-20.8 15.93-6.12 32.62 1.11L480-832.92l96.23-41.39q16.69-7.23 32.62-1.11 15.92 6.11 24.38 20.8l52.62 90 101.23 22.93q16.92 4.23 26.5 18.31 9.57 14.07 8.34 31l-9.46 104.23 69.31 77.77q12.46 12.69 12.46 30.38 0 17.69-12.46 30.38l-69.31 77.77 9.46 104.23q1.23 16.93-8.34 31-9.58 14.08-26.5 18.31l-101.23 22.93-52.62 90q-8.46 14.69-24.38 20.8-15.93 6.12-32.62-1.11L480-127.08l-96.23 41.39q-16.69 7.23-32.62 1.11-15.92-6.11-24.38-20.8ZM376-156l104-44.46L586.46-156 644-254l112-26.46L746-394l74-86-74-86.46L756-682l-112-24-60-98-104 44.46L373.54-804 316-706l-112 24 10 115.54L140-480l74 86-10 116 112 24 60 98Zm104-324Z"
    />
  </svg>
  <svg
    v-else-if="name == 'phone'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M300.62-46q-38.35 0-64.48-26.14Q210-98.27 210-136.62v-686.76q0-38.35 26.14-64.48Q262.27-914 300.62-914h358.76q38.35 0 64.48 26.14Q750-861.73 750-823.38v686.76q0 38.35-26.14 64.48Q697.73-46 659.38-46H300.62ZM276-162v25.38q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h358.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93V-162H276Zm0-66h408v-504H276v504Zm0-570h408v-25.38q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H300.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93V-798Zm0 0v-50 50Zm0 636v50-50Z"
    />
  </svg>
  <svg
    v-else-if="name == 'edit'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M194-194h43.92l407.93-407.92-43.93-42.93L194-236.92V-194Zm-20.69 66q-18.73 0-32.02-13.29Q128-154.58 128-173.31v-53.61q0-18.27 7.23-35.29t19.92-29.71l530.08-531.62q5.15-5.48 11.57-6.97 6.43-1.49 12.49-1.49 6.06 0 11.12.54 5.05.54 11.44 6.15l92.69 91.93q5.61 6.38 6.54 12 .92 5.63.92 12.25 0 6.13-1.74 12.06-1.74 5.92-6.72 11.07L291.92-155.15q-12.69 12.69-29.71 19.92-17.02 7.23-35.29 7.23h-53.61Zm581.46-581.31-45.46-44.46 45.46 44.46ZM624.5-623.5l-22.58-21.35 43.93 42.93-21.35-21.58Z"
    />
  </svg>
  <svg
    v-else-if="name == 'download'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M479-349.08q-9.03 0-16.74-3.02-7.72-3.03-14.88-10.44L320.62-489.31q-9.85-9.93-10.12-23.31-.27-13.38 11.12-24 10.61-10.61 23.26-10.23 12.66.39 23.27 11L446-457v-302q0-13.54 9.73-23.27T479-792q13.54 0 23.27 9.73T512-759v302l78.85-78.85q9.58-9.84 22.75-10.11 13.17-.27 23.78 10.34 11.39 10.62 11 23.66-.38 13.04-11 23.65L510.62-362.54q-7.16 7.41-14.88 10.44-7.71 3.02-16.74 3.02ZM258.62-168q-38.35 0-64.48-26.14Q168-220.27 168-258.62v-63.92q0-13.54 9.73-23.27t23.27-9.73q13.54 0 23.27 9.73t9.73 23.27v63.92q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h442.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-63.92q0-13.54 9.73-23.27t23.27-9.73q13.54 0 23.27 9.73t9.73 23.27v63.92q0 38.35-26.14 64.48Q739.73-168 701.38-168H258.62Z"
    />
  </svg>
  <svg
    v-else-if="name == 'backspace'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M550.15-433.69 613-370.85q9.62 9.62 22.77 10 13.15.39 23.54-10 10.38-10.38 10.38-23.15 0-12.77-10.38-23.15L596.46-480l62.85-62.85q9.61-9.61 10-22.77.38-13.15-10-23.53-10.39-10.39-23.16-10.39T613-589.15l-62.85 62.84-62.84-62.84q-9.62-9.62-22.77-10-13.16-.39-23.54 10-10.38 10.38-10.38 23.15 0 12.77 10.38 23.15L503.85-480 441-417.15q-9.62 9.61-10 22.77-.38 13.15 10 23.53 10.38 10.39 23.15 10.39 12.77 0 23.16-10.39l62.84-62.84ZM367.92-168q-21.68 0-41.45-9.5-19.77-9.5-32.24-26.58L127.85-425.46q-17.7-24.92-17.7-54.54 0-29.62 17.7-54.54l166.38-221.38Q306.7-773 326.47-782.5q19.77-9.5 41.45-9.5h413.46q38.85 0 64.74 25.88Q872-740.23 872-701.38v442.76q0 38.85-25.88 64.74Q820.23-168 781.38-168H367.92Zm-1-65h415.46q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-444.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H366.92q-6.15 0-11.15 2.31t-8.85 6.92L180.54-495.38q-5.39 6.92-5.39 15.38t5.39 15.38l166.38 222.39q3.85 4.61 8.85 6.92 5 2.31 11.15 2.31Zm123.77-247Z"
    />
  </svg>
  <svg
    v-else-if="name == 'favorite'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M479.23-171.23q-17.69 0-33.11-6.23-15.43-6.23-28.12-17.92l-60.92-55.47q-101.77-93-185.43-187.15Q88-532.15 88-642q0-88.27 60.85-148.14Q209.69-850 297.51-850q50.26 0 97.64 23.62 47.39 23.61 84.85 71.92 37.46-48.31 84.85-71.92Q612.23-850 662.49-850q87.82 0 148.66 59.86Q872-730.27 872-642q0 112.15-87.5 207.46T603.92-252.62l-62.69 57.24q-12.69 11.69-28.5 17.92-15.81 6.23-33.5 6.23Zm-30.77-509.69q-23.92-45.93-63.69-74.5Q345-784 298-784q-61.71 0-102.86 40Q154-704 154-641.86q0 46.17 25.58 93.24 25.57 47.08 68.84 97.43 43.27 50.34 99.16 101.92 55.88 51.58 116.27 106.65 6.92 6.16 16.15 6.16 9.23 0 16.15-6.16 60.39-55.07 116.27-106.65 55.89-51.58 99.16-101.92 43.27-50.35 68.84-97.43Q806-595.69 806-641.86 806-704 764.86-744 723.71-784 662-784q-47 0-87.27 28.58-40.27 28.57-63.19 74.5-4.16 9.38-12.87 14.57-8.72 5.2-18 5.2-9.29 0-18.17-5.2-8.88-5.19-14.04-14.57ZM480-509.08Z"
    />
  </svg>
  <svg
    v-else-if="name == 'visibility'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M480.18-329.85q70.97 0 120.47-49.68t49.5-120.65q0-70.97-49.68-120.47t-120.65-49.5q-70.97 0-120.47 49.68t-49.5 120.65q0 70.97 49.68 120.47t120.65 49.5Zm-.3-60.15Q434-390 402-422.12q-32-32.12-32-78T402.12-578q32.12-32 78-32T558-577.88q32 32.12 32 78T557.88-422q-32.12 32-78 32Zm.12 180q-128.62 0-237.58-67.27-108.96-67.27-171.88-179.11-6-10.24-8.62-21.16-2.61-10.92-2.61-22.42 0-11.5 2.61-22.46 2.62-10.96 8.62-21.2 62.92-111.84 171.88-179.11Q351.38-790 480-790t237.58 67.27q108.96 67.27 171.88 179.11 6 10.24 8.62 21.16 2.61 10.92 2.61 22.42 0 11.5-2.61 22.46-2.62 10.96-8.62 21.2-62.92 111.84-171.88 179.11Q608.62-210 480-210Zm0-290Zm0 224q115 0 211.87-60.58T840-500q-51.26-102.84-148.13-163.42Q595-724 480-724t-211.87 60.58Q171.26-602.84 120-500q51.26 102.84 148.13 163.42Q365-276 480-276Z"
    />
  </svg>
  <svg
    v-else-if="name == 'checkmark'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="m382-353.38 344.54-344.54q13.01-12.93 30.43-13.12 17.41-.19 30.72 12.91Q801-685.04 801-667.75t-13.31 30.6l-371.38 371q-14.67 14.84-34.22 14.84-19.55 0-34.4-14.84l-173-173q-12.92-12.66-12.8-30.06.11-17.4 13.21-30.71 13.09-13.31 30.38-13.31t30.6 13.31L382-353.38Z"
    />
  </svg>
  <svg
    v-else-if="name == 'play-button'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M438.54-343.77 604-451.62q15.69-10.16 15.69-28.23T604-508.38L438.54-616.23q-16.92-11.46-34.73-.96Q386-606.68 386-586.85v213.7q0 19.83 17.81 30.34 17.81 10.5 34.73-.96ZM480.13-88q-81.31 0-152.89-30.86-71.57-30.86-124.52-83.76-52.95-52.9-83.83-124.42Q88-398.55 88-479.87q0-81.56 30.92-153.37 30.92-71.8 83.92-124.91 53-53.12 124.42-83.48Q398.67-872 479.87-872q81.55 0 153.35 30.34 71.79 30.34 124.92 83.42 53.13 53.08 83.49 124.84Q872-561.64 872-480.05q0 81.59-30.34 152.83-30.34 71.23-83.41 124.28-53.07 53.05-124.81 84Q561.7-88 480.13-88Zm-.13-66q136.51 0 231.26-94.74Q806-343.49 806-480t-94.74-231.26Q616.51-806 480-806t-231.26 94.74Q154-616.51 154-480t94.74 231.26Q343.49-154 480-154Zm0-326Z"
    />
  </svg>
  <svg
    v-else-if="name == 'link-external'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M218.62-128q-38.35 0-64.48-26.14Q128-180.27 128-218.62v-522.76q0-38.35 26.14-64.48Q180.27-832 218.62-832h216.61q13.54 0 23.27 9.73t9.73 23.27q0 13.54-9.73 23.27T435.23-766H218.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v522.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h522.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-216.61q0-13.54 9.73-23.27t23.27-9.73q13.54 0 23.27 9.73t9.73 23.27v216.61q0 38.35-26.14 64.48Q779.73-128 741.38-128H218.62ZM766-719.69 409.69-363.38q-9.61 9.61-22.77 10-13.15.38-23.54-10Q353-373.77 353-386.54t10.38-23.15L719.69-766H612q-13.54 0-23.27-9.73T579-799q0-13.54 9.73-23.27T612-832h174.69q18.93 0 32.12 13.19Q832-805.62 832-786.69V-612q0 13.54-9.73 23.27T799-579q-13.54 0-23.27-9.73T766-612v-107.69Z"
    />
  </svg>
  <svg
    v-else-if="name == 'star'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="m373-314 107-64 107 65-29-121 95-82-124-11-49-115-49 115-124 10 95 82-29 121Zm107 13.08-138.23 83.69q-10.39 5.15-19.85 4.54-9.46-.62-17.61-5.77-8.16-5.16-11.85-15.04-3.69-9.88-.46-20.73l36.62-157.39-121.77-105.84q-9.39-6.39-11.62-16.5-2.23-10.12 1.23-18.73 3.46-8.62 11.62-15.16 8.15-6.53 18.23-7.77l159.61-13.69L448.69-738q4.69-11.62 12.81-16.04 8.12-4.42 18.5-4.42t18.5 4.42q8.12 4.42 12.81 16.04l62.77 148.69 159.61 13.69q10.08 1.24 18.23 7.77 8.16 6.54 11.62 15.16 3.46 8.61 1.23 18.73-2.23 10.11-11.62 16.5L631.38-411.62 668-254.23q3.23 10.85-.46 20.73t-11.85 15.04q-8.15 5.15-17.61 5.77-9.46.61-19.85-4.54L480-300.92ZM480-467Z"
    />
  </svg>
  <svg
    v-else-if="name == 'more'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M222.3-426q-22.34 0-38.09-15.91-15.75-15.91-15.75-38.25t15.91-38.09Q200.28-534 222.62-534t38.09 15.91q15.75 15.91 15.75 38.25t-15.91 38.09Q244.64-426 222.3-426Zm257.54 0q-22.34 0-38.09-15.91Q426-457.82 426-480.16t15.91-38.09Q457.82-534 480.16-534t38.09 15.91Q534-502.18 534-479.84t-15.91 38.09Q502.18-426 479.84-426Zm257.54 0q-22.34 0-38.09-15.91-15.75-15.91-15.75-38.25t15.91-38.09Q715.36-534 737.7-534t38.09 15.91q15.75 15.91 15.75 38.25t-15.91 38.09Q759.72-426 737.38-426Z"
    />
  </svg>
  <svg
    v-else-if="name == 'filter'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M441.31-241q-13.5 0-23.25-9.76t-9.75-23.27q0-13.51 9.75-23.24 9.75-9.73 23.25-9.73h77.61q13.5 0 23.25 9.76t9.75 23.27q0 13.51-9.75 23.24-9.75 9.73-23.25 9.73h-77.61Zm-161-206q-13.5 0-23.25-9.76t-9.75-23.27q0-13.51 9.75-23.24 9.75-9.73 23.25-9.73h397.61q13.5 0 23.25 9.76t9.75 23.27q0 13.51-9.75 23.24-9.75 9.73-23.25 9.73H280.31ZM161-653q-13.5 0-23.25-9.76T128-686.03q0-13.51 9.75-23.24Q147.5-719 161-719h638q13.5 0 23.25 9.76t9.75 23.27q0 13.51-9.75 23.24Q812.5-653 799-653H161Z"
    />
  </svg>
  <svg
    v-else-if="name == 'delete'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M300.62-128q-38.66 0-64.64-25.98Q210-179.96 210-218.62V-724h-20q-13.5 0-23.25-9.76T157-757.03q0-13.51 9.75-23.24Q176.5-790 190-790h168q0-15.38 11.49-27.08 11.49-11.69 27.28-11.69h168.46q15.79 0 27.28 11.49Q604-805.78 604-790h168q13.5 0 23.25 9.76t9.75 23.27q0 13.51-9.75 23.24Q785.5-724 772-724h-20v505.38q0 38.66-25.98 64.64Q700.04-128 661.38-128H300.62ZM686-724H276v505.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h360.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7V-724ZM404.33-275q13.52 0 23.25-9.75t9.73-23.25v-302q0-13.5-9.76-23.25T404.28-643q-13.51 0-23.24 9.75-9.73 9.75-9.73 23.25v302q0 13.5 9.76 23.25 9.75 9.75 23.26 9.75Zm153.39 0q13.51 0 23.24-9.75 9.73-9.75 9.73-23.25v-302q0-13.5-9.76-23.25-9.75-9.75-23.26-9.75-13.52 0-23.25 9.75T524.69-610v302q0 13.5 9.76 23.25t23.27 9.75ZM276-724v530-530Z"
    />
  </svg>
  <svg
    v-else-if="name == 'print'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M340.62-120q-38.66 0-64.64-25.98Q250-171.96 250-210.62V-274h-62.31q-38.65 0-64.63-25.98-25.98-25.98-25.98-64.64v-154.76q0-44 31.07-75 31.08-31 74.93-31h553.84q44 0 75 31t31 75v154.76q0 38.66-25.98 64.64Q810.96-274 772.31-274H710v63.38q0 38.66-25.98 64.64Q658.04-120 619.38-120H340.62ZM187.69-340H259q13.54-21.31 34.57-33.42 21.03-12.12 47.05-12.12h278.76q26.02 0 47.05 12.12Q687.46-361.31 701-340h71.31q10.77 0 17.69-6.92 6.92-6.93 6.92-17.7V-519q0-17.38-11.21-28.88t-27.79-11.5H203.08q-17 0-28.5 11.5t-11.5 28.5v154.76q0 10.77 6.92 17.7 6.92 6.92 17.69 6.92ZM644-625.38v-99.39q0-10.77-6.92-17.69-6.93-6.92-17.7-6.92H340.62q-10.77 0-17.7 6.92-6.92 6.92-6.92 17.69v99.39h-66v-99.39q0-38.65 25.98-64.63 25.98-25.99 64.64-25.99h278.76q38.66 0 64.64 25.99Q710-763.42 710-724.77v99.39h-66Zm41.12 186q20.5 0 35-15t14.5-35.5q0-20.5-14.38-35-14.37-14.5-35.62-14.5-20 0-35 14.37-15 14.38-15 35.63 0 20 15 35t35.5 15ZM644-210.62v-84.3q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H340.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v84.3q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h278.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7ZM187.69-559.38h-24.61 633.84-609.23Z"
    />
  </svg>
  <svg
    v-else-if="name === 'home'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M242-200h122.69v-203.08q0-18.73 13.29-32.02 13.29-13.28 32.02-13.28h140q18.73 0 32.02 13.28 13.29 13.29 13.29 32.02V-200H718v-345.69q0-6.16-2.69-11.16t-7.31-8.84L494.62-726q-6.16-5.38-14.62-5.38-8.46 0-14.62 5.38L252-565.69q-4.62 3.84-7.31 8.84-2.69 5-2.69 11.16V-200Zm-66 0v-345.69q0-21.58 9.79-40.89 9.78-19.31 27.06-31.8l213.38-161.08q24.91-17.92 53.65-17.92t53.89 17.92l213.38 161.08q17.28 12.49 27.06 31.8 9.79 19.31 9.79 40.89V-200q0 28.08-18.96 47.04T718-134H574.62q-18.74 0-32.02-13.29-13.29-13.29-13.29-32.02v-203.07h-98.62v203.07q0 18.73-13.29 32.02Q404.12-134 385.38-134H242q-28.08 0-47.04-18.96T176-200Zm304-266.46Z"
    />
  </svg>
  <svg
    v-else-if="name === 'thumb-up'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M821.38-612q35.48 0 63.05 27.57T912-521.38v55.66q0 6.16-1.23 13.56-1.23 7.39-5.23 13.85L788.27-159.29q-10.19 20.37-30.95 33.83Q736.57-112 714.18-112H280.92v-500l211.39-214.38q18.84-18.85 41.23-24.97 22.38-6.11 41.15 3.27 18.77 9.39 25.66 32.2 6.88 22.8.19 49.11L565.38-612h256Zm-474.46 27.08V-178h355.54q8.46 0 17.31-4.62 8.85-4.61 13.46-15.38L846-466v-55.38q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H479.69L533-771.46 346.92-584.92ZM176.62-112q-38.66 0-64.64-25.98Q86-163.96 86-202.62v-318.76q0-38.66 25.98-64.64Q137.96-612 176.62-612h104.3v66h-104.3q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v318.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h104.3v66h-104.3Zm170.3-66v-406.92V-178Z"
    />
  </svg>
  <svg
    v-else-if="name === 'thumb-down'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M138.62-348q-35.48 0-63.05-27.57T48-438.62v-55.66q0-6.16 1.23-13.56 1.23-7.39 5.23-13.85l117.27-279.02q10.19-20.37 30.95-33.83Q223.43-848 245.82-848h433.26v500L467.69-133.62q-18.84 18.85-41.23 24.97-22.38 6.11-41.15-3.27-18.77-9.39-25.66-32.2-6.88-22.8-.19-49.11L394.62-348h-256Zm474.46-27.08V-782H257.54q-8.46 0-17.31 4.62-8.85 4.61-13.46 15.38L114-494v55.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h341.69L427-188.54l186.08-186.54ZM783.38-848q38.66 0 64.64 25.98Q874-796.04 874-757.38v318.76q0 38.66-25.98 64.64Q822.04-348 783.38-348h-104.3v-66h104.3q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-318.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92h-104.3v-66h104.3Zm-170.3 66v406.92V-782Z"
    />
  </svg>
  <svg
    v-else-if="name === 'edit-document'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M533.15-133.31v-42.92q0-9.46 3.23-17.18 3.24-7.72 10.47-14.67l203.61-202.61q7.76-7.27 16.38-10.68 8.63-3.4 17.25-3.4 9.06 0 18.33 3.08 9.27 3.07 16.43 10.23l37 38.77q7.92 7.92 11 16.31 3.07 8.38 3.07 17.76 0 9.39-3.4 18.05-3.4 8.65-10.67 16.26L653.23-101.69q-6.94 7.23-14.66 10.46Q630.85-88 621.38-88h-42.92q-18.92 0-32.11-13.19-13.2-13.19-13.2-32.12Zm288.39-205.31-37-37.76 37 37.76Zm-240 202.24h38l138.69-138.93-37-38.54-139.69 139.47v38ZM240.62-88q-38.35 0-64.48-26.14Q150-140.27 150-178.62v-602.76q0-38.35 26.14-64.48Q202.27-872 240.62-872h271.53q18.36 0 35.47 7.32t29.53 19.83l165.7 167.7q12.51 12.42 19.83 29.53 7.32 17.11 7.32 35.47v10q0 13.21-9.73 22.1-9.73 8.9-23.27 8.9-11.54 0-20.27-6.23-8.73-6.24-12.73-16.77V-618H561.31q-18.93 0-32.12-13.19Q516-644.38 516-663.31V-806H240.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v602.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h164.15q13.54 0 23.27 9.73t9.73 23.27q0 13.54-9.73 23.27T404.77-88H240.62ZM216-154v-652 652Zm524.23-141.08-19-18.77 37 38.54-18-19.77Z"
    />
  </svg>
  <svg
    v-else-if="name === 'arrow-upward'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M446-663.15 240.46-457.62q-9.84 9.85-23.77 10.62-13.92.77-24.54-9.85-11.38-10.61-11-24.15.39-13.54 11-24.15l255.23-255.23q7.24-7.24 14.7-10.35 7.46-3.12 16.92-3.12t16.92 3.12q7.46 3.11 14.7 10.35l257.23 257.23q10.61 10.61 11 24.15.38 13.54-11 24.15-10.62 10.62-23.77 10.62-13.16 0-23.77-10.62L512-663.15V-201q0 13.54-9.73 23.27T479-168q-13.54 0-23.27-9.73T446-201v-462.15Z"
    />
  </svg>
  <svg
    v-else-if="name === 'arrow-forward'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M663.15-446H201q-13.54 0-23.27-9.73T168-479q0-13.54 9.73-23.27T201-512h462.15L455.62-719.54q-9.85-9.84-10.62-23.77-.77-13.92 9.85-24.54 10.61-11.38 24.15-11 13.54.39 24.15 11l257.23 257.23q7.24 7.24 10.35 14.7 3.12 7.46 3.12 16.92t-3.12 16.92q-3.11 7.46-10.35 14.7L505.15-192.15q-10.61 10.61-24.15 11-13.54.38-24.15-11-10.62-10.62-10.62-23.77 0-13.16 10.62-23.77L663.15-446Z"
    />
  </svg>
  <svg
    v-else-if="name === 'arrow-downward'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M446-296.85V-759q0-13.54 9.73-23.27T479-792q13.54 0 23.27 9.73T512-759v462.15l207.54-207.53q9.84-9.85 23.77-10.62 13.92-.77 24.54 9.85 11.38 10.61 11 24.15-.39 13.54-11 24.15L510.62-199.62q-7.24 7.24-14.7 10.35-7.46 3.12-16.92 3.12t-16.92-3.12q-7.46-3.11-14.7-10.35L192.15-454.85q-10.61-10.61-11-24.15-.38-13.54 11-24.15 10.62-10.62 23.77-10.62 13.16 0 23.77 10.62L446-296.85Z"
    />
  </svg>
  <svg
    v-else-if="name === 'arrow-back'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="m296.85-446 205.53 205.54q9.85 9.84 10.62 23.77.77 13.92-9.85 24.54-10.61 11.38-24.15 11-13.54-.39-24.15-11L199.62-447.38q-7.24-7.24-10.35-14.7-3.12-7.46-3.12-16.92t3.12-16.92q3.11-7.46 10.35-14.7l257.23-257.23q10.61-10.61 24.15-11 13.54-.38 24.15 11 10.62 10.62 10.62 23.77 0 13.16-10.62 23.77L296.85-512H759q13.54 0 23.27 9.73T792-479q0 13.54-9.73 23.27T759-446H296.85Z"
    />
  </svg>
  <svg
    v-else-if="name == 'arrow-key-up'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M481-541.69 316.15-376.85q-9.61 9.62-22.77 10-13.15.39-23.53-10-10.39-10.38-10.39-23.15 0-12.77 10.39-23.15l179.53-179.54q13.7-13.69 31.62-13.69t31.62 13.69l179.53 179.54q9.62 9.61 10 22.77.39 13.15-10 23.53-10.38 10.39-23.15 10.39-12.77 0-23.15-10.39L481-541.69Z"
    />
  </svg>
  <svg
    v-else-if="name == 'arrow-key-right'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M515.69-481 352.85-643.85q-9.62-9.61-10-22.77-.39-13.15 10-23.53 10.38-10.39 23.15-10.39 12.77 0 23.15 10.39l177.54 177.53q7.23 7.24 10.35 14.7 3.11 7.46 3.11 16.92t-3.11 16.92q-3.12 7.46-10.35 14.7L399.15-271.85q-9.61 9.62-22.77 10-13.15.39-23.53-10-10.39-10.38-10.39-23.15 0-12.77 10.39-23.15L515.69-481Z"
    />
  </svg>
  <svg
    v-else-if="name == 'arrow-key-down'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M481-371.85q-9.46 0-16.92-3.11-7.46-3.12-14.7-10.35L269.85-564.85q-9.62-9.61-10-22.77-.39-13.15 10-23.53 10.38-10.39 23.15-10.39 12.77 0 23.15 10.39L481-446.31l164.85-164.84q9.61-9.62 22.77-10 13.15-.39 23.53 10 10.39 10.38 10.39 23.15 0 12.77-10.39 23.15L512.62-385.31q-7.24 7.23-14.7 10.35-7.46 3.11-16.92 3.11Z"
    />
  </svg>
  <svg
    v-else-if="name == 'arrow-key-left'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="m422.31-481 162.84 162.85q9.62 9.61 10 22.77.39 13.15-10 23.53-10.38 10.39-23.15 10.39-12.77 0-23.15-10.39L361.31-449.38q-7.23-7.24-10.35-14.7-3.11-7.46-3.11-16.92t3.11-16.92q3.12-7.46 10.35-14.7l177.54-177.53q9.61-9.62 22.77-10 13.15-.39 23.53 10 10.39 10.38 10.39 23.15 0 12.77-10.39 23.15L422.31-481Z"
    />
  </svg>
  <svg
    v-else-if="name == 'success'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M364-134q-95.08 0-162.54-67.46T134-364v-232q0-95.08 67.46-162.54T364-826h232q95.08 0 162.54 67.46T826-596v232q0 95.08-67.46 162.54T596-134H364Zm76-286.31-66.85-66.84q-9.61-9.62-22.77-10-13.15-.39-23.53 10-10.39 10.38-10.39 23.15 0 12.77 10.39 23.15l81.53 81.54q13.7 13.69 31.62 13.69t31.62-13.69l177.53-177.54q9.62-9.61 10-22.77.39-13.15-10-23.53-10.38-10.39-23.15-10.39-12.77 0-23.15 10.39L440-420.31ZM364-200h232q69 0 116.5-47.5T760-364v-232q0-69-47.5-116.5T596-760H364q-69 0-116.5 47.5T200-596v232q0 69 47.5 116.5T364-200Zm116-280Z"
    />
  </svg>
  <svg
    v-else-if="name == 'warning'"
    :data-sprite-icon-name="name"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M136.03-118q-13.34 0-23.53-6.94-10.2-6.94-16.19-16.21-6.36-9.16-6.8-21-.43-11.85 6.67-24.33l344.87-592.04q7.1-12.48 16.8-17.33 9.69-4.84 22.15-4.84 12.46 0 22.15 4.84 9.7 4.85 16.8 17.33l344.87 592.04q7.1 12.48 6.67 24.33-.44 11.84-6.8 21-5.99 9.27-16.19 16.21-10.19 6.94-23.53 6.94H136.03ZM172-184h616L480-714 172-184Zm309-44.38q15.46 0 26.54-11.08 11.08-11.08 11.08-26.54 0-15.46-11.08-26.54-11.08-11.08-26.54-11.08-15.46 0-26.54 11.08-11.08 11.08-11.08 26.54 0 15.46 11.08 26.54 11.08 11.08 26.54 11.08Zm.03-112.24q13.51 0 23.24-9.75 9.73-9.75 9.73-23.25v-138q0-13.5-9.76-23.25t-23.27-9.75q-13.51 0-23.24 9.75-9.73 9.75-9.73 23.25v138q0 13.5 9.76 23.25t23.27 9.75ZM480-449Z"
    />
  </svg>
  <svg
    v-else-if="name == 'info'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M481.03-284q13.51 0 23.24-9.75Q514-303.5 514-317v-170q0-13.5-9.76-23.25T480.97-520q-13.51 0-23.24 9.75Q448-500.5 448-487v170q0 13.5 9.76 23.25t23.27 9.75Zm-1.21-308.92q14.64 0 24.72-9.9 10.08-9.91 10.08-24.54 0-14.64-9.91-24.72-9.9-10.07-24.53-10.07-14.64 0-24.72 9.9-10.08 9.9-10.08 24.54 0 14.63 9.91 24.71 9.9 10.08 24.53 10.08ZM480.13-88q-81.31 0-152.89-30.86-71.57-30.86-124.52-83.76-52.95-52.9-83.83-124.42Q88-398.55 88-479.87q0-81.56 30.92-153.37 30.92-71.8 83.92-124.91 53-53.12 124.42-83.48Q398.67-872 479.87-872q81.55 0 153.35 30.34 71.79 30.34 124.92 83.42 53.13 53.08 83.49 124.84Q872-561.64 872-480.05q0 81.59-30.34 152.83-30.34 71.23-83.41 124.28-53.07 53.05-124.81 84Q561.7-88 480.13-88Zm-.13-66q136.51 0 231.26-94.74Q806-343.49 806-480t-94.74-231.26Q616.51-806 480-806t-231.26 94.74Q154-616.51 154-480t94.74 231.26Q343.49-154 480-154Zm0-326Z"
    />
  </svg>
  <svg
    v-else-if="name == 'error'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M479.82-284.08q14.64 0 24.72-9.9 10.08-9.9 10.08-24.54 0-14.63-9.91-24.71-9.9-10.08-24.53-10.08-14.64 0-24.72 9.9-10.08 9.91-10.08 24.54 0 14.64 9.91 24.72 9.9 10.07 24.53 10.07Zm.21-146.46q13.51 0 23.24-9.75 9.73-9.75 9.73-23.25v-179.08q0-13.5-9.76-23.25t-23.27-9.75q-13.51 0-23.24 9.75-9.73 9.75-9.73 23.25v179.08q0 13.5 9.76 23.25t23.27 9.75ZM370.77-128q-18.69 0-35.64-7.32-16.94-7.32-29.36-19.83L155.15-305.77q-12.51-12.42-19.83-29.36-7.32-16.95-7.32-35.64v-218.46q0-18.69 7.32-35.64 7.32-16.94 19.83-29.36l150.62-150.62q12.42-12.51 29.36-19.83 16.95-7.32 35.64-7.32h218.46q18.69 0 35.64 7.32 16.94 7.32 29.36 19.83l150.62 150.62q12.51 12.42 19.83 29.36 7.32 16.95 7.32 35.64v218.46q0 18.69-7.32 35.64-7.32 16.94-19.83 29.36L654.23-155.15q-12.42 12.51-29.36 19.83-16.95 7.32-35.64 7.32H370.77ZM360-194h240l166-166v-240L600-766H360L194-600v240l166 166Zm120-286Z"
    />
  </svg>
  <svg
    v-else-if="name == 'sound-on'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M757.38-447h-91.23q-13.5 0-23.25-9.76t-9.75-23.27q0-13.51 9.75-23.24 9.75-9.73 23.25-9.73h91.23q13.5 0 23.25 9.76t9.75 23.27q0 13.51-9.75 23.24-9.75 9.73-23.25 9.73ZM591.62-299.92q9.38-10.08 22.8-12.31 13.43-2.23 23.5 6.15l70.93 53.39q11.07 9.38 13.3 23.31 2.23 13.92-7.15 25-9.38 10.07-22.81 12.3-13.42 2.23-23.5-6.15l-70.92-53.39q-11.08-9.38-13.31-23.3-2.23-13.93 7.16-25Zm114.15-410.46L634.85-657q-10.08 8.38-23.5 6.15-13.43-2.23-22.81-12.3-9.39-11.08-7.16-25 2.24-13.93 13.31-23.31l70.93-53.39q10.07-8.38 23.5-6.15 13.42 2.23 22.8 12.31 9.39 11.07 7.16 25-2.23 13.92-13.31 23.31ZM280.54-380H174.92q-18.73 0-32.02-13.29-13.28-13.29-13.28-32.02v-109.38q0-18.73 13.28-32.02Q156.19-580 174.92-580h105.62l112.38-114.39q15.77-16.76 37.23-7.48 21.47 9.28 21.47 30.87v382q0 21.59-21.47 30.87-21.46 9.28-37.23-7.48L280.54-380Zm105.08-214-78 80h-112v68h112l78 80v-228Zm-95 114Z"
    />
  </svg>
  <svg
    v-else-if="name == 'sound-off'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="m716.38-433.69-82.84 82.84q-9.62 9.62-22.77 10-13.15.39-23.54-10-10.38-10.38-10.38-23.15 0-12.77 10.38-23.15L670.08-480l-82.85-82.85q-9.61-9.61-10-22.77-.38-13.15 10-23.53 10.39-10.39 23.15-10.39 12.77 0 23.16 10.39l82.84 82.84 82.85-82.84q9.62-9.62 22.77-10 13.15-.39 23.54 10 10.38 10.38 10.38 23.15 0 12.77-10.38 23.15L762.69-480l82.85 82.85q9.61 9.61 10 22.77.38 13.15-10 23.53-10.39 10.39-23.16 10.39-12.76 0-23.15-10.39l-82.85-82.84ZM282.23-380H176.62q-18.93 0-32.12-13.19-13.19-13.19-13.19-32.12v-109.38q0-18.93 13.19-32.12Q157.69-580 176.62-580h105.61l112.39-114.39q15.76-16.76 37.23-7.65 21.46 9.12 21.46 31.04v382q0 21.92-21.46 31.04-21.47 9.11-37.23-7.65L282.23-380Zm105.08-214-78 80h-112v68h112l78 80v-228Zm-95 114Z"
    />
  </svg>
  <svg
    v-else-if="name == 'upload-file'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M446-410v158.77q0 13.64 9.16 22.82t22.77 9.18q13.61 0 22.84-9.18t9.23-22.82V-410l57.85 57.85q4.06 3.92 9.14 5.88 5.09 1.96 11.24 2.35 6.15.38 11.88-1.58t10.21-5.88q9.22-8.62 8.83-21.16-.38-12.54-9-21.15L509.62-494.23q-7.16-7.41-14.88-10.44-7.71-3.02-16.74-3.02-9.03 0-16.74 3.02-7.72 3.03-14.88 10.44L345.85-393.69q-7.85 7.84-8.62 20.77-.77 12.92 7.85 21.54 8.61 9.38 21.15 9 12.54-.39 21.15-9L446-410ZM260.62-88q-38.35 0-64.48-26.14Q170-140.27 170-178.62v-602.76q0-38.35 26.14-64.48Q222.27-872 260.62-872h269.53q18.36 0 35.47 7.32t29.53 19.83l167.7 166.7q12.51 12.42 19.83 29.53 7.32 17.11 7.32 35.47v434.53q0 38.35-26.14 64.48Q737.73-88 699.38-88H260.62ZM536-663.31V-806H260.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v602.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h438.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93V-618H581.31q-18.93 0-32.12-13.19Q536-644.38 536-663.31ZM236-806v188-188 652-652Z"
    />
  </svg>
  <svg
    v-else-if="name == 'calendar-month'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="M218.62-90q-38.35 0-64.48-26.14Q128-142.27 128-180.62v-522.76q0-38.35 26.14-64.48Q180.27-794 218.62-794h38.76v-50.69q0-14.31 10.12-24.43 10.12-10.11 24.42-10.11 14.31 0 24.43 10.11 10.11 10.12 10.11 24.43V-794h310.16v-52.23q0-13.54 9.73-23.27t23.27-9.73q13.53 0 23.26 9.73 9.74 9.73 9.74 23.27V-794h38.76q38.35 0 64.48 26.14Q832-741.73 832-703.38v522.76q0 38.35-26.14 64.48Q779.73-90 741.38-90H218.62Zm0-66h522.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-374.76H194v374.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM194-621.39h572v-81.99q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H218.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v81.99Zm0 0V-728v106.61Zm286 233.54q-14.38 0-25.58-11.19-11.19-11.19-11.19-25.58 0-14.38 11.19-25.57 11.2-11.19 25.58-11.19 14.38 0 25.58 11.19 11.19 11.19 11.19 25.57 0 14.39-11.19 25.58-11.2 11.19-25.58 11.19Zm-160 0q-14.38 0-25.58-11.19-11.19-11.19-11.19-25.58 0-14.38 11.19-25.57 11.2-11.19 25.58-11.19 14.38 0 25.58 11.19 11.19 11.19 11.19 25.57 0 14.39-11.19 25.58-11.2 11.19-25.58 11.19Zm320 0q-14.38 0-25.58-11.19-11.19-11.19-11.19-25.58 0-14.38 11.19-25.57 11.2-11.19 25.58-11.19 14.38 0 25.58 11.19 11.19 11.19 11.19 25.57 0 14.39-11.19 25.58-11.2 11.19-25.58 11.19ZM480-234q-14.38 0-25.58-11.19-11.19-11.19-11.19-25.58 0-14.38 11.19-25.58 11.2-11.19 25.58-11.19 14.38 0 25.58 11.19 11.19 11.2 11.19 25.58 0 14.39-11.19 25.58Q494.38-234 480-234Zm-160 0q-14.38 0-25.58-11.19-11.19-11.19-11.19-25.58 0-14.38 11.19-25.58 11.2-11.19 25.58-11.19 14.38 0 25.58 11.19 11.19 11.2 11.19 25.58 0 14.39-11.19 25.58Q334.38-234 320-234Zm320 0q-14.38 0-25.58-11.19-11.19-11.19-11.19-25.58 0-14.38 11.19-25.58 11.2-11.19 25.58-11.19 14.38 0 25.58 11.19 11.19 11.2 11.19 25.58 0 14.39-11.19 25.58Q654.38-234 640-234Z"
    />
  </svg>
  <!-- LOGO icons below: -->
  <svg
    v-else-if="name == 'logo'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    fill="currentColor"
  >
    <path
      d="m435.695-427.869-47.377-52.105 47.377-52.105c21.745-23.216 42.178-43.385 71.907-45.381-.158-158.416-91.709-231.74-240.775-231.74C119.18-809.2 19.697-733.408 0-602.358l167.397 24.477c15.022-58.408 50.53-77.37 96.331-77.37 45.802 0 73.43 20.538 73.43 58.408 0 40.287-35.507 52.893-66.339 52.893h-28.416v127.898h28.416c30.78 0 66.34 12.606 66.34 52.893 0 37.923-27.629 58.408-73.43 58.408-45.803 0-81.31-18.962-96.332-77.37L0-357.643c21.325 133.466 116.868 206.844 266.827 206.844 146.755 0 240.617-73.325 240.775-231.688-29.73-1.944-50.162-22.113-71.907-45.382zM960-641.803v-153.951H507.602v218.294c1.576-.105 3.046-.368 4.727-.368 36.295 0 59.196 21.325 59.196 50.53 0 19.749-6.303 31.567-24.477 47.377 18.174 15.81 24.477 27.628 24.477 47.377 0 29.204-22.901 50.53-59.196 50.53-1.628 0-3.099-.263-4.727-.368v218.294H678.15v-201.329h242.403v-153.95H678.15v-122.384H960z"
    />
  </svg>
  <svg
    v-else-if="name == 'alka'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
  >
    <g transform="matrix(3.97263 0 0 -3.97263 -1193.295 700.464)">
      <path
        fill="#000000"
        d="M423.605 284.044c-.366.467-.248 1.081.08 1.417.312.322 28.46 29.67 28.46 29.67.317.326.761.53 1.252.53l21.579.003c.588 0 .855-.697.461-1.107-.343-.357-27.514-28.706-27.828-29.032a1.076 1.076 0 0 1-.041-1.405c.432-.525 27.537-34.464 27.897-34.909.349-.434.051-1.067-.497-1.067l-21.562-.002c-.562 0-1.029.241-1.384.682-.354.441-28.162 34.896-28.417 35.22m-48.119-34.808c0-.608.483-1.091 1.098-1.091h15.243c.592 0 1.084.483 1.084 1.084v95.833c0 .581-.473 1.092-1.084 1.092h-15.25c-.582 0-1.091-.502-1.091-1.082z"
      />
      <path
        fill="#000000"
        d="M425.569 257.944c0-6.014-4.873-10.887-10.887-10.887-6.013 0-10.889 4.873-10.889 10.887 0 6.013 4.876 10.888 10.889 10.888 6.014 0 10.887-4.875 10.887-10.888"
      />
      <path
        fill="#000000"
        d="M422.364 309.993s2.93 19.865 3.204 26.345v.036c-.013 6-4.88 10.867-10.891 10.867-6.006 0-10.874-4.867-10.884-10.867v-.036c.259-6.736 3.287-26.552 3.287-26.552l5.341-32.507c.188-1.163 1.224-1.916 2.26-1.916.983 0 2.045.706 2.258 1.902zm-75.146-49.709a.968.968 0 0 0-.821-.969c-2.125-.238-16.216-1.009-21.486 1.692-3.485 1.785-4.912 5.496-4.912 7.82 0 2.845 1.656 5.688 4.567 7.032 2.369 1.093 5.247 1.685 9.584 1.685l11.989.003c.58 0 1.08-.48 1.08-1.086zm-6.548 55.37h-29.365a1.093 1.093 0 0 1-1.103-1.076v-10.905c0-.612.512-1.088 1.091-1.088h27.403c4.235 0 8.517-3.686 8.517-8.462 0-.591.006-2.414.006-3.511 0-.547-.48-1.097-1.08-1.097l-12.086.008c-9.543 0-16.285-1.353-21.825-3.902-6.497-2.989-10.722-9.41-10.722-16.798 0-6.208 2.85-10.972 6.903-14.423 7.427-6.327 23.528-8.96 55.321-6.241.41.035.89.442.89 1.07l.003 42.491c0 12.451-9.854 23.934-23.953 23.934m182.827-55.37c0-.563-.437-.926-.818-.969-2.126-.238-16.215-1.009-21.487 1.692-3.484 1.785-4.911 5.496-4.911 7.82 0 2.845 1.655 5.688 4.567 7.032 2.368 1.093 5.246 1.685 9.583 1.685l11.988.003a1.09 1.09 0 0 0 1.082-1.086zm-6.546 55.37h-29.366a1.094 1.094 0 0 1-1.104-1.076v-10.905c0-.612.513-1.088 1.092-1.088h27.404c4.234 0 8.516-3.686 8.516-8.462 0-.591.009-2.414.009-3.511 0-.547-.481-1.097-1.08-1.097l-12.087.008c-9.543 0-16.287-1.353-21.825-3.902-6.499-2.989-10.721-9.41-10.721-16.798 0-6.208 2.85-10.972 6.9-14.423 7.429-6.327 23.53-8.96 55.322-6.241.411.035.89.442.89 1.07l.004 42.491c0 12.451-9.854 23.934-23.954 23.934"
      />
    </g>
  </svg>
  <svg
    v-else-if="name == 'mobilepay'"
    :data-sprite-icon-name="name"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 192.05 173.5"
  >
    <linearGradient
      id="linear-gradient"
      x1="89.43"
      x2="108.25"
      y1="84.98"
      y2="75.33"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#504678" />
      <stop offset=".55" stop-color="#504678" stop-opacity=".41" />
      <stop offset=".97" stop-color="#504678" stop-opacity=".47" />
    </linearGradient>
    <g>
      <path
        fill="#5a78ff"
        d="M89 118.55a5.84 5.84 0 0 1-5.38-3.6l-20.15-48.6a5.83 5.83 0 0 1 3.15-7.61L87.71 50a5.83 5.83 0 0 1 7.61 3.15l20.13 48.59a5.83 5.83 0 0 1-3.15 7.61l-21.09 8.75a5.82 5.82 0 0 1-2.21.45Zm1-65.84a2.67 2.67 0 0 0-1 .21l-21.17 8.72a2.69 2.69 0 0 0-1.46 3.51l20.13 48.6a2.69 2.69 0 0 0 3.5 1.45l21.08-8.73a2.69 2.69 0 0 0 1.46-3.51l-20.12-48.6a2.69 2.69 0 0 0-2.48-1.66Z"
      />
      <path
        fill="#5a78ff"
        d="M107.55 85.29c-6 2.5-11.06 5.95-14.36 10.1l-8.5-20.52a35.89 35.89 0 0 1 14.36-10.1c6-2.5 12.12-3.71 17.29-3l8.5 20.52a35.87 35.87 0 0 0-17.29 3Z"
      />
      <path
        fill="url(#linear-gradient)"
        opacity=".4"
        style="mix-blend-mode:multiply"
        d="M107.55 85.29c-6 2.5-11.06 5.95-14.36 10.1l-8.5-20.52a35.89 35.89 0 0 1 14.36-10.1c6-2.5 12.12-3.71 17.29-3l8.5 20.52a35.87 35.87 0 0 0-17.29 3Z"
      />
      <path
        fill="#5a78ff"
        d="M110.32 91.55c-6.53 0-12.5 1.26-17.13 3.84V74.63a35.89 35.89 0 0 1 17.13-3.84c6.53 0 12.62 1.21 17.13 3.84v20.76a35.87 35.87 0 0 0-17.13-3.84Z"
      />
    </g>
  </svg>
  <!--
  ############################################################
  ############################################################
  Legacy icons below:
  Remove when possible.
  They may still be used in the Sitecore repository
  ############################################################
  ############################################################
  -->
  <svg
    v-else-if="name == 'x'"
    :data-sprite-icon-name="name"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M1 9l8-8M9 9L1 1" />
    </g>
  </svg>
  <svg
    v-else-if="name == 'heart'"
    :data-sprite-icon-name="name"
    viewBox="0 0 24 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.302 3.58a6.583 6.583 0 00-10.447-1.71L12 2.65l-.827-.752A6.523 6.523 0 005.485.092 6.473 6.473 0 00.7 3.579a6.4 6.4 0 001.21 7.47l9.374 9.657a1 1 0 001.434 0l9.361-9.64a6.415 6.415 0 001.223-7.486z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    }
  }
};
</script>
