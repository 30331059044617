<template>
  <div>
    <v-text
      class="d-flex mr-sm-3 py-1 py-sm-0"
      font-size="1"
      tag="a"
      :href="url"
    >
      <v-icon
        size="xsmall"
        name="visibility"
        class="text-primary mr-1"
      ></v-icon>
      Læs bladet
    </v-text>
    <v-text
      v-if="fileUrl"
      class="d-flex py-1 py-sm-0"
      font-size="1"
      tag="a"
      :href="fileUrl"
    >
      <v-icon size="xxsmall" name="download" class="text-primary mr-1"></v-icon>
      Download
    </v-text>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    fileUrl: {
      type: String,
      default: null
    }
  }
};
</script>
